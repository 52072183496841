import React, { useState, useRef } from 'react'
import styled from 'styled-components'
import { Link } from 'gatsby' 

const StyledMenu = styled.div`
    display: none; 

    @media(max-width: 768px) {
        display: block;
    }
`; 

const StyledMenuButton = styled.button`
    display: none;

    @media(max-width: 768px) { 
        display: block;
        position: relative; 
        z-index: 10; 
        padding: 15px;
        margin-right: -30px;
        border: 0; 
        background-color: transparent; 
        color: inherit; 
        text-transform: none; 
        transition-timing-function: linear;
        transition-duration: 0.15s;
        transition-property: opacity, filter;
        float: right;
    }

    .button-box {
        display: inline-block;
        position: relative;
        width: 40px;
        height: 24px;
        justify-content: flex-end;
    }

    .button-box-inner { 
        position: absolute;
        top: 50%;
        width: 30px;
        height: 2px;
        border-radius: 2px;
        background-color: var(--fc-accent-color-2);
        transition-duration: 0.22s;
        transition-property: transform;
        transition-delay: ${props => (props.menuOpen ? `0.12s` : `0s`)};
        transform: rotate(${props => (props.menuOpen ? `225deg` : `0deg`)});
        transition-timing-function: cubic-bezier(
        ${props => (props.menuOpen ? `0.215, 0.61, 0.355, 1` : `0.55, 0.055, 0.675, 0.19`)}
        );

        &:before,
        &:after {
          content: '';
          display: block;
          position: absolute;
          left: auto;
          right: 0;
          width: 20px;
          height: 2px;
          border-radius: 10px;
          background-color: var(--fc-accent-color-2);
          transition-timing-function: ease;
          transition-duration: 0.15s;
          transition-property: transform;
        }
        &:before {
            width: ${props => (props.menuOpen ? `100%` : `50%`)};
            top: ${props => (props.menuOpen ? `0` : `-10px`)};
            opacity: ${props => (props.menuOpen ? 0 : 1)};
            transition: ${({ menuOpen }) =>
            menuOpen ? 'top 0.1s ease-out, opacity 0.1s ease-out 0.12s;' : 'top 0.1s ease-in 0.25s, opacity 0.1s ease-in;'};
        }
        &:after {
            width: ${props => (props.menuOpen ? `100%` : `50%`)};
            bottom: ${props => (props.menuOpen ? `0` : `-10px`)};
            transform: rotate(${props => (props.menuOpen ? `-90deg` : `0`)});
            transition: ${({ menuOpen }) => (menuOpen ? 'bottom 0.1s ease-out, transform 0.22s cubic-bezier(0.215, 0.61, 0.355, 1) 0.12s' : 'bottom 0.1s ease-in 0.25s, transform 0.22s cubic-bezier(0.55, 0.055, 0.675, 0.19)')};
        }
    }
`;

const StyledHiddenMenu = styled.aside`
    display: none; 

    @media (max-width: 768px) {
        display: block;
        position: fixed; 
        top: 0; 
        bottom: 0; 
        right: 0; 
        padding: 125px 10px; 
        width: 100%;
        z-index: 9;
        background-color: rgba(255, 255, 255, 0.96);
        height: 100vh;
        outline: 0;
        transform: translateX(${props => (props.menuOpen ? 0 : 100)}vw);
        visibility: ${props => (props.menuOpen ? 'visible' : 'hidden')};
    }

    nav {
        width: 100%; 
        flex-direction: 100%; 
        text-align: center; 
    }

    ol {
        padding: 0; 
        margin: 0;
        list-style: none; 
        width: 100%; 

        li {
            position: relative; 
            margin: 0 auto 20px; 
            counter-increment: item 1; 

            @media (max-width: 600px) {
                margin: 0 auto 10px;
            }
        }

        a {
            width: 100%;
            padding: 3px 20px 20px; 
            font-weight: 400;
            font-size: var(--font-size-display);
            font-family: var(--link-font);
            color: var(--fc-accent-color-1);

            &:hover,
            &:focus {
                color: var(--fc-accent-color-2);
            }
        }

        .resume-button { 
            display: block; 
            margin: 0 auto;
            border: none;
            background-color: var(--fc-accent-color-1);
            color: #fff;
            border-radius: 38px;
            cursor: pointer;
            font-size: 14px;
            font-weight: 400;
            letter-spacing: .02em;
            padding: 0.5em 2.25em;
            text-decoration: none;
            text-align: center;
            transform: scale(1);
            transition: color 225ms cubic-bezier(0.29, 0.19, 0.405, 1),transform 225ms cubic-bezier(0.175, 0.885, 0.32, 1.275);
            line-height: 2.2;
            min-width: 125px;
            box-shadow: 1px 1px 2px 0 rgb(0 0 0 / 15%);
            will-change: transform;
            width: 150px;
    
            &:hover, 
            &:focus {
                background: var(--fc-accent-color-2);
                transform: scale(1.03);
                color: #fff;
            }
        }
    }
`;

const Menu = ({ navLinks }) => {
    const [menuOpen, setMenuOpen] = useState(false);

    const toggleMenu = () => setMenuOpen(!menuOpen); 

    const buttonRef= useRef(null); 
    const navRef = useRef(null); 

    return (
        <StyledMenu>
            <div>
                <StyledMenuButton onClick={toggleMenu} menuOpen={menuOpen} ref={buttonRef}>
                    <div className="button-box">
                        <div className="button-box-inner" />
                    </div>
                </StyledMenuButton>
                <StyledHiddenMenu menuOpen={menuOpen} aria-hidden={!menuOpen} tabIndex={menuOpen ? 1 : -1}>
                    <nav ref={navRef}>
                        {navLinks && (
                            <ol>
                                {navLinks.map(({url, name}, i) => (
                                    <li key={i}>
                                        <Link to={url} onClick={() => setMenuOpen(false)}>{name}</Link>
                                    </li>
                                ))}
                               <li><a href="/resume.pdf" className="resume-button">Resume</a></li>
                            </ol>
                        )}
                    </nav>
                </StyledHiddenMenu>
            </div>
        </StyledMenu>
    )
}

export { Menu }
