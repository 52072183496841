import React from 'react'
import PropTypes from 'prop-types'

const Head = ({ title, description, siteUrl }) => {
    const seo = { 
        title: title, 
        description: description, 
        url: siteUrl,
    };

    return (
        <head>
            <html lang="en" /> 
            <title>Evander Mendon&#231;a | Portfolio</title>

            <meta name="description" content={seo.description} />
            <meta name="image" content="/hero.png" />

            <meta property="og:title" content={seo.title} />
            <meta property="og:description" content={seo.description} />
            <meta property="og:image" content="/hero.png" />
            <meta property="og:url" content={seo.url} />
            <meta property="og:type" content="website" />

            <meta name="twitter:card" content="summary_large_image" />
            <meta name="twitter:creator" content="thisisevander" />
            <meta name="twitter:title" content={seo.title} />
            <meta name="twitter:description" content={seo.description} />
            <meta name="twitter:image" content="/hero.png" />
        </head>
    )
}

export { Head }

Head.propTypes = {
    title: PropTypes.string, 
    description: PropTypes.string, 
    siteUrl: PropTypes.string,
}

Head.defaultProps = {
    title: 'Evander Mendonca', 
    description: 'Evander Mendonca is a software engineer based out of South Florida who builds and develops great applications and websites (primarily using React and Node).', 
    siteUrl: 'https://evander.co/', 
}
