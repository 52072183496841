import React from 'react'
import { Nav } from '../components/nav'
import { Head } from '../components/head'
import { Footer } from '../components/footer'
import { GlobalStyle } from '../styles'
import styled from 'styled-components'
import PropTypes from "prop-types"

if (typeof window !== "undefined") {
    // eslint-disable-next-line global-require
    require("smooth-scroll")('a[href*="#"]')
}

const StyledLayout = styled.div`
    display: flex; 
    flex-direction: column; 
    min-height: 100vh; 
`;

const Layout = ({ children, location }) => {
    const isHome = location.pathname === '/';
    return (
        <>
            <Head />

            <div id="root">
                <GlobalStyle />
                
                <StyledLayout>
                    <Nav />

                    <div id="content">
                        {children}
                        <Footer isHome={isHome} />
                    </div>
                </StyledLayout>
            </div>  
        </>
    )
}

Layout.propTypes = {
    children: PropTypes.node.isRequired,
}

export { Layout }