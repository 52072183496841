import React from 'react'
import styled from 'styled-components'
import { Link } from 'gatsby'
import { Menu } from '../components/menu'

const StyledHeader = styled.header`
    position: relative; 
    z-index: 15; 
    padding: 0 50px; 
    width: 100%: 
    top: 0;

    @media (max-width: 1080px) {
        padding: 0 40px;
    }

    @media (max-width: 768px) {
        padding: 0 25px;
    }
`;

const StyledNav = styled.nav`
    width: 100%; 
    z-index: 16; 
    position: relative; 
    text-align: center;
`; 

const StyledNavLinks = styled.div`
    align-items: center; 
    display: inline-block;
    font-size: var(--fs-xlarge);
    font-weight: 400;
    color: var(--fc-accent-color-1);

    @media (max-width: 768px) {
        display: none;
    }   

    ul { 
        display: flex; 
        padding: 0; 
        margin: 15px 0; 
        list-style: none;
        align-items: center; 
        justify-content: space-between;

        li { 
            margin: 0 10px; 
            position: relative; 
            display: inline-block;

            &:after {
                content: '';
                display: block;
                position: absolute;
                height: 1px;
                bottom: -3px;
                width: 100%;
                transform: scaleX(0);
                background-color: transparent;
                transition: transform 225ms ease,background-color 225ms ease;
            }

            &:hover:after,
            &:focus:after {
                transform: scaleX(1);
                background-color: var(--fc-accent-color-2);
            }

            a {
                padding: 10px;
            }
        }
    }
`; 

const Nav = () => {
    const navLinks = [
        { 
            name: 'About',
            url: '/#about', 
        }, 
        { 
            name: 'Projects',
            url: '/#projects', 
        }, 
        {
            name: 'Experience', 
            url: '/#experience', 
        }, 
        { 
            name: 'Contact',
            url: '/#contact', 
        }
    ]; 

    return (
        <StyledHeader>
            <StyledNav>
                <StyledNavLinks>
                    <ul>
                        {navLinks && navLinks.map(({ url, name }, i) => (
                            <li key={i}>
                                <Link to={url}>{name}</Link>
                            </li>
                        ))}
                    </ul>
                </StyledNavLinks>

                <Menu navLinks={navLinks} />
            </StyledNav>
        </StyledHeader>
    )
}

export { Nav }
