import { createGlobalStyle } from 'styled-components'

const GlobalStyle = createGlobalStyle`
    @import url('https://fonts.googleapis.com/css2?family=Sanchez:ital@1&display=swap');

    :root { 
        --display-font: 'Sanchez', serif;
        --body-font: "brown", "helvetica neue", "helvetica", sans-serif;

        --fs-xxsmall: 13px;
        --fs-xsmall: 14px;
        --fs-small: 15px;
        --fs-regular: 16px; 
        --fs-medium: 17px; 
        --fs-large: 18px; 
        --fs-xlarge: 22px; 
        --fs-section-header: 24px;

        --fc-highlighter: #00bfff;
        --fc-accent-color-1: #3c3c57; 
        --fc-accent-color-2: #fc766a; /*#6bcdfd;*/

        --primary-background-color: #ffffff;
        --secondary-background-color: #f2f2f2; 
        --offwhite-hover-color: #f5f5f5;

        --border-color: #dae0e5;
    }

    html {
        box-sizing: border-box; 
        width: 100%; 
    }

    *, 
    *:before, 
    *:after { 
        box-sizing: inherit; 
    }

    body { 
        margin: 0; 
        width: 100%; 
        min-height: 100%; 
        overflow-x: hidden; 
        font-weight: 300; 
        line-height: 1.3; 
        font-family: var(--body-font);
        font-size: var(--fs-medium);
        background: var(--primary-background-color);
    }

    main { 
        margin: 0 auto; 
        width: 100%; 
        max-width: 1600px; 
        min-height: 100vh; 
        padding: 50px 100px;

        @media (max-width: 600px) {
            padding: 50px 50px;
        }
        
    }

    section {
        margin: 0 auto; 
        padding: 50px 0 25px 0;
        max-width: 1000px;
    }

    .large-heading {
        margin: 0;
        font-size: clamp(40px, 8vw, 70px);
        font-family: var(--display-font);
    }

    mark {
        margin: 0 -0.4em;
        padding: -0.1em 0.4em;
        border-radius: 0.8em 0.3em;
        background: transparent;
        background-image: linear-gradient(
            to right,
            rgba(0, 191, 255, 0.1),
            rgba(0, 191, 255, 0.7) 4%,
            rgba(0, 191, 255, 0.3)
        );
        -webkit-box-decoration-break: clone;
        box-decoration-break: clone;
    }

    a {
        display: inline-block;
        text-decoration: none; 
        text-decoration-skip-ink: auto; 
        color: inherit; 
        position: relative; 
        cursor: pointer; 
        font-family: var(--body-font);
        font-size: var(--fs-small);

        &:hover, 
        &:focus {
            color: var(--fc-accent-color-2);
        }
    }

    .section-header { 
        display: flex;
        position: relative;
        width: 100%;
        white-space: nowrap;
        margin: 40px 0px 40px;
        font-family: var(--display-font); 
        font-size: var(--fs-section-header);
        font-size: clamp(26px, 5vw, 32px);
        
        &:after {
            content: "";
            position: relative;
            width: 300px;
            height: 1px;
            margin-left: 20px;
            margin-top: 20px;
            background-color: var(--fc-accent-color-1);

            @media (max-width: 1080px) {
                width: 200px;
            }

            @media (max-width: 768px) {
                width: 100%;
            }

            @media (max-width: 600px) {
                margin-left: 10px;
                margin-top: 17.5px;
            }
        }
    }
`; 

export default GlobalStyle;