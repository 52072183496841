import React from 'react'
import styled from 'styled-components'

const StyledFooter = styled.footer`
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: column; 
    height: auto; 
    min-height: 70px; 
    padding: 15px;
    text-align: center; 

    position: ${({ isHome }) => 
        isHome === false ? 'absolute' : ''
    };

    bottom: ${({ isHome }) => 
        isHome === false ? '0' : ''
    };

    margin: ${({ isHome }) => 
        isHome === false ? '0 auto' : ''
    };

    width: ${({ isHome }) => 
        isHome === false ? '100%' : ''
    };
`; 

const StyledSocial = styled.div`
    display: block;
    width: 100%; 
    max-width: 270px;
    margin: 0;

    ul {
        display: flex;
        justify-content: space-between;
        align-items: center;
        display: inline-block;
        padding: 0;
        margin: 0; 
        list-style: none;

        li {
            display: inline-block;
        }

        a {
            padding: 10px;
            color: var(--fc-accent-color-1);

            &:hover {
                color: var(--fc-accent-color-2)
            }

            svg {
                width: 20px;
                height: 20px;
            }
        }
    }
`;

const StyledCredit = styled.div `
    line-height: 1; 

    a {
        padding: 0 0 10px 0;

        span {
            font-size: var(--fs-xsmall);
            margin-left: 10px;
        }
    }
`;

const Footer  = ({ isHome }) => {
    console.log(isHome);
    return (
        <StyledFooter isHome={isHome}>
            <StyledSocial>
                <ul>
                    <li>
                        <a href="https://twitter.com/thisisevander">
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                role="img"
                                viewBox="0 0 24 24"
                                fill="none"
                                stroke="currentColor"
                                strokeWidth="2"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                className="feather feather-twitter">
                                <path d="M23 3a10.9 10.9 0 0 1-3.14 1.53 4.48 4.48 0 0 0-7.86 3v1A10.66 10.66 0 0 1 3 4s-4 9 5 13a11.64 11.64 0 0 1-7 2c9 5 20 0 20-11.5a4.5 4.5 0 0 0-.08-.83A7.72 7.72 0 0 0 23 3z"></path>
                            </svg>
                        </a>
                    </li>
                    <li>
                        <a href="https://github.com/evandermendonca">
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                role="img"
                                viewBox="0 0 24 24"
                                fill="none"
                                stroke="currentColor"
                                strokeWidth="2"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                className="feather feather-github">
                                <path d="M9 19c-5 1.5-5-2.5-7-3m14 6v-3.87a3.37 3.37 0 0 0-.94-2.61c3.14-.35 6.44-1.54 6.44-7A5.44 5.44 0 0 0 20 4.77 5.07 5.07 0 0 0 19.91 1S18.73.65 16 2.48a13.38 13.38 0 0 0-7 0C6.27.65 5.09 1 5.09 1A5.07 5.07 0 0 0 5 4.77a5.44 5.44 0 0 0-1.5 3.78c0 5.42 3.3 6.61 6.44 7A3.37 3.37 0 0 0 9 18.13V22"></path>
                            </svg>
                        </a>
                    </li>
                    <li>
                        <a href="https://linkedin.com/in/evandermendonca">
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                role="img"
                                viewBox="0 0 24 24"
                                fill="none"
                                stroke="currentColor"
                                strokeWidth="2"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                className="feather feather-linkedin">
                                <path d="M16 8a6 6 0 0 1 6 6v7h-4v-7a2 2 0 0 0-2-2 2 2 0 0 0-2 2v7h-4v-7a6 6 0 0 1 6-6z"></path>
                                <rect x="2" y="9" width="4" height="12"></rect>
                                <circle cx="4" cy="4" r="2"></circle>
                            </svg>
                        </a>
                    </li>
                </ul>
            </StyledSocial>
            <StyledCredit>
                <a href="https://github.com/evandermendonca/v2">
                    <div>Created by Evander Mendon&#231;a <br/><span>/2021</span></div>
                </a>
            </StyledCredit>
        </StyledFooter>
    )
}

export { Footer }